import styled  from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
	width: 100%;
	height: auto;

	@media(max-width: 1080px) {
		display: flex;
		justify-content: center;
	}
`

